export const environment = {
  production: false,
  msalConfig: {
    auth: {
      clientId: '0992e37d-3d8d-491a-ac99-2bdc0492822e',
      authority:
        'https://login.microsoftonline.com/fe16105c-2cd4-4447-b05f-440cb233041a',
    },
  },
  apiConfig: {
    uri: 'api://0992e37d-3d8d-491a-ac99-2bdc0492822e/.default',
    scopes: ['api://0992e37d-3d8d-491a-ac99-2bdc0492822e/VoiceUser'],
  },
  rbacAPIConfig: {
    uri: 'https://rbac.dev.udtsp.net/api',
    scopes: ['api://0992e37d-3d8d-491a-ac99-2bdc0492822e/VoiceUser'],
  },
  voiceAPIConfig: {
    uri: 'https://voice.dev.udtsp.net/api',
    scopes: ['api://0992e37d-3d8d-491a-ac99-2bdc0492822e/VoiceUser'],
    defaultPageSize: 10,
  }
};
